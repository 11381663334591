import { render, staticRenderFns } from "./mm-header_h5.vue?vue&type=template&id=097e2996&scoped=true"
import script from "./mm-header_h5.vue?vue&type=script&lang=js"
export * from "./mm-header_h5.vue?vue&type=script&lang=js"
import style0 from "./mm-header_h5.vue?vue&type=style&index=0&id=097e2996&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "097e2996",
  null
  
)

export default component.exports